import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { numberToFixed2 } from '@shared/lib/numbers';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { ReactComponent as PriceIcon } from '@icons/custom/bid-light.svg';
import { ReactComponent as PortfolioIcon } from '@icons/custom/portfolio-light.svg';
import { ReactComponent as CheckmarkIcon } from '@icons/wolfkit-light/checkmark-light.svg';
import { getDiffInDays } from '@utils/date-time';
import { BodyBold, BodyMediumSemiBold, BodySemiBold, BodySmall, BodySmallSemiBold, } from '@components/styled/Typography';
import { ContainerColumn, ContainerRow } from '@components/styled';
import FollowingIcon from '@shared/ui/icons/FollowingIcon';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import VerificationIcon from '@shared/ui/icons/VerificationIcon';
import ExchangeIconBadge from '@shared/ui/badges/ExchangeIconBadge';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import Button from '@shared/ui/buttons/Button';
const SectionHeaderText = styled(BodySemiBold)(() => ({
    lineHeight: '18px',
    letterSpacing: '-0.072px',
}));
export const SearchSectionHeader = ({ type, count, }) => {
    const text = () => {
        switch (type) {
            case 'client':
                return Trans({
                    i18nKey: 'navigation.search.sections.clients',
                    ns: 'common',
                    values: { count },
                });
            case 'portfolio':
                return Trans({
                    i18nKey: 'navigation.search.sections.portfolios',
                    ns: 'common',
                    values: { count },
                });
            case 'trader':
                return Trans({
                    i18nKey: 'navigation.search.sections.traders',
                    ns: 'common',
                    values: { count },
                });
            default:
                return null;
        }
    };
    return _jsx(SectionHeaderText, { children: text() });
};
const PortfolioItemContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.m,
}));
const AvatarContainer = styled(ContainerColumn)(() => ({
    position: 'relative',
    width: 49,
    height: 42,
    minHeight: 49,
    flexShrink: 0,
    alignItems: 'end',
    borderRadius: '50%',
}));
const TitleContainer = styled(ContainerRow)(props => ({
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs * 0.5,
}));
const ItemTitle = styled(BodyMediumSemiBold)(props => ({
    color: props.theme.palette.text.primary,
}));
const PortfolioItemContent = styled(ContainerColumn)(props => ({
    height: '100%',
    gap: props.theme.spacing_sizes.xs * 0.5,
}));
const PortfolioItemNumbers = styled(ContainerRow)(props => ({
    padding: `${props.theme.spacing_sizes.xs * 0.25}px 0`,
    gap: props.theme.spacing_sizes.s,
}));
const WalletTypeComponent = styled(BodySmallSemiBold)(props => ({
    padding: `0 ${props.theme.spacing_sizes.s}px`,
    color: props.theme.palette.text.secondary,
}));
export const PortfolioItemResult = ({ portfolioImageUrl = undefined, portfolioName = '', verified = false, isFollowing = false, followingPlace = undefined, followersCount, monthlyFee, walletType, exchange, }) => (_jsxs(PortfolioItemContainer, { children: [_jsxs(AvatarContainer, { children: [_jsx(AvatarImage, { imageUrl: portfolioImageUrl, publicName: portfolioName, size: 'medium' }), _jsx(ExchangeIconBadge, { type: exchange, left: -9 })] }), _jsxs(PortfolioItemContent, { children: [_jsxs(TitleContainer, { children: [_jsx(ItemTitle, { children: portfolioName }), _jsx(VerificationIcon, { verified: verified })] }), _jsxs(PortfolioItemNumbers, { children: [_jsx(FollowingIcon, { count: followersCount, followerPosition: followingPlace, isFollowing: isFollowing }), _jsx(IconText, { IconComponent: (_jsx(Icon, { IconComponent: PriceIcon, size: 16 })), text: `$ ${numberToFixed2(monthlyFee)}` }), _jsx(WalletTypeComponent, { children: walletType })] })] })] }));
const TraderItemContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    justifyContent: 'space-between',
}));
const TraderGeneralInfo = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.xs,
}));
const TraderItemContent = styled(ContainerColumn)(props => ({
    padding: `${props.theme.spacing_sizes.xs * 0.25}px 0`,
    gap: props.theme.spacing_sizes.s * 0.5,
}));
const TraderJoinDate = styled(BodySmall)(props => ({
    color: props.theme.palette.text.primary,
    textAlign: 'end',
    lineHeight: '18px',
}));
const TraderPnl = styled(TraderItemContent)(() => ({
    padding: 0,
    width: 'auto',
}));
const TraderItemNumbers = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.xs * 1.25,
}));
const IncomeBadge = withPercentageIncomeBadge(BodyBold);
const Divider = styled.span(props => ({
    height: 16,
    border: `1px solid ${props.theme.palette.text.secondary}`,
    marginRight: props.theme.spacing_sizes.xs * 0.25,
}));
const TraderAvatarContainer = styled(AvatarContainer)(props => ({
    paddingTop: props.theme.spacing_sizes.xs * 0.5,
    width: 40,
    height: 40,
    minHeight: 40,
}));
const SubscriptionIconText = styled(IconText)(props => ({
    color: props.theme.palette.primary.main,
}));
export const TraderItemResult = ({ traderImageUrl = undefined, traderName = '', followers, creationDate, incomeAmount, portfoliosCount, subscribedPortfoliosCount, verified = false, }) => (_jsxs(TraderItemContainer, { children: [_jsxs(TraderGeneralInfo, { children: [_jsx(TraderAvatarContainer, { children: _jsx(AvatarImage, { imageUrl: traderImageUrl, publicName: traderName, size: 'medium' }) }), _jsxs(TraderItemContent, { children: [_jsxs(TitleContainer, { children: [_jsx(ItemTitle, { children: traderName }), _jsx(VerificationIcon, { verified: verified })] }), _jsxs(TraderItemNumbers, { children: [_jsx(FollowingIcon, { count: followers }), _jsx(IconText, { IconComponent: (_jsx(Icon, { IconComponent: PortfolioIcon, size: 16 })), text: `${portfoliosCount}` }), subscribedPortfoliosCount !== 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(SubscriptionIconText, { IconComponent: (_jsx(Icon, { IconComponent: CheckmarkIcon, size: 12 })), text: subscribedPortfoliosCount })] }))] })] })] }), _jsxs(TraderPnl, { children: [_jsx(TraderJoinDate, { children: `${getDiffInDays(new Date(creationDate))} ${Trans({
                        i18nKey: 'periods.day',
                        ns: 'common',
                        value: {
                            count: getDiffInDays(new Date(creationDate)),
                        },
                    })}` }), _jsx(IncomeBadge, { badgeSize: 'small', badgeVariant: 'tinted', percentageIncome: incomeAmount, displayInt: true })] })] }));
const ClientItemContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.xs,
}));
const ClientAvatarContainer = styled(AvatarContainer)(() => ({
    width: 40,
    height: 40,
    minHeight: 40,
}));
const ClientItemInfo = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const FollowersCount = styled(BodySmallSemiBold)(props => ({
    color: props.theme.palette.text.secondary,
    lineHeight: '14px',
}));
export const ClientItemResult = ({ followersCount, clientImageUrl = undefined, clientName = '', }) => (_jsxs(ClientItemContainer, { children: [_jsx(ClientAvatarContainer, { children: _jsx(AvatarImage, { imageUrl: clientImageUrl, publicName: clientName }) }), _jsxs(ClientItemInfo, { children: [_jsx(ItemTitle, { children: clientName }), _jsx(FollowersCount, { children: `${followersCount} ${Trans({
                        i18nKey: 'plural_variables.follower',
                        ns: 'common',
                        count: followersCount,
                    })}` })] })] }));
export const ShowMoreButton = ({ onClick = undefined, showMore = false, }) => {
    const { t } = useTranslation();
    return (_jsx(Button, { onClick: onClick, color: 'primary-light', variant: 'tinted', size: 'small', children: t(showMore ? 'actions.show_more' : 'actions.show_less', { ns: 'common' }) }));
};
export const LIST_LOADING_HEIGHT = 114;
export const LIST_CONTAINER_HEIGHT = 94;
export const LIST_WIDTH = 320;
export const PORTFOLIO_ITEM_HEIGHT = 68;
export const TRADER_ITEM_HEIGHT = 68;
export const CLIENT_ITEM_HEIGHT = 60;
export const SECTION_TITLE_HEIGHT = 38;
export const SHOW_MORE_BUTTON_HEIGHT = 50;
export const LoaderContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: LIST_CONTAINER_HEIGHT,
}));
